import { createStore } from 'owa-satcheljs';
export const getStore = createStore('hybridspace-isAttendeeRoomState', {
    // Contains the cache of places emailIds and whether it is a room or not
    isAttendeeARoomMap: new Map<string, boolean>(),
});

export function isAttendeeCached(email: string): boolean {
    return getStore().isAttendeeARoomMap.has(email);
}

export function isAttendeeARoom(email: string): boolean {
    return !!getStore().isAttendeeARoomMap.get(email);
}
