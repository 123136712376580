import { isLinkedCalendarEntryV2 } from 'owa-calendar-properties';
import { isFeatureEnabled } from 'owa-feature-flags';
import type { CalendarEntry, LinkedCalendarEntryV2, LocalCalendarEntry } from 'owa-graph-schema';

export default function isWritableCalendar(calendarEntry: CalendarEntry | null): boolean {
    // Return false if calendarEntry is null or undefined
    if (!calendarEntry) {
        return false;
    }

    if (
        isFeatureEnabled('cal-enable-HybridModel-for-calendarSharing') &&
        isLinkedCalendarEntryV2(calendarEntry)
    ) {
        const linkedCalendarEntry = calendarEntry as LinkedCalendarEntryV2;
        return (
            !linkedCalendarEntry.IsReadOnly &&
            (!!linkedCalendarEntry.EffectiveRights?.CreateContents ||
                !!linkedCalendarEntry.CanCreateContents)
        );
    }

    // General handling for other calendar entries
    const localCalendarEntry = calendarEntry as LocalCalendarEntry;

    return !localCalendarEntry.IsReadOnly && !!localCalendarEntry.EffectiveRights?.CreateContents;
}
